import React, { useState, useEffect, useCallback } from 'react';
import { HighlightedCard } from 'src/components/shared';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby-link';
import { getPreviewVideoSrc } from 'src/utils/sdk-utils';
import { ContentSelectors, IState } from '@pvolve/sdk/src/redux/selectors';
import { notifyBugsnagError } from 'src/utils/bugsnag';

interface HighlightedSeriesCardProps {
    content: any;
    Styles: React.CSSProperties;
}

const HighlightedSeriesCard = ({ content, Styles }: HighlightedSeriesCardProps) => {
    const [previewVideoSrc, setPreviewVideoSrc] = useState();
    const [previewVideoFetched, setPreviewVideoFetched] = useState(false);

    const seriesID = content.id;
    const thumbnailData = useSelector(ContentSelectors.asset.indexedBy.id);
    const thumbnailUrl = thumbnailData[content.thumbnailId]?.fields.file.url ?? {};

    useEffect(() => {
        async function loadPreviewVideo() {
            try {
                const { uri } = await getPreviewVideoSrc(seriesID, 'series');

                if (uri) {
                    setPreviewVideoSrc(uri);
                }
            } catch (error) {
                notifyBugsnagError(error);
                console.log({ error });
            } finally {
                setPreviewVideoFetched(true);
            }
        }

        loadPreviewVideo();
    }, []);

    const handleClick = useCallback(() => {
        navigate(`/series/${content.slug}`);
    }, [content]);

    return (
        <HighlightedCard
            title={content.title}
            description={content.description}
            isNew={content.isNew ? content.isNew : false}
            previewLoaded={previewVideoFetched}
            previewMedia={previewVideoSrc}
            previewThumbnail={thumbnailUrl}
            handleClick={handleClick}
            Styles={Styles}
        />
    );
};

export default HighlightedSeriesCard;
